import React, { lazy } from "react";
import Loading from "./Loading";

/*
 * For an explanation on how to use this, visit this url (INCOGNITO IF BLOCKED): https://medium.com/@michael.kutateladze/react-router-with-a-single-config-file-61777f306b4f
 */

const routeKeys = {
  ABOUT_US: "ABOUT_US",
  ADMIN_TOOLS: "ADMIN_TOOLS",
  AGREEMENT_TEXT: "AGREEMENT_TEXT",
  CASE_REFERRALS: "CASE_REFERRALS",
  CASE_REFERRAL_MANAGE: "CASE_REFERRAL_MANAGE",
  CASE_REFERRALS_REMINDERS: "CASE_REFERRALS_REMINDERS",
  CASE_REFERRALS_REPORTS: "CASE_REFERRALS_REPORTS",
  CASE_REFERRALS_FILE_CLASSIFICATION: "CASE_REFERRALS_FILE_CLASSIFICATION",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CT_NOTIFICATIONS: "CT_NOTIFICATIONS",
  DECONFLICTION_HISTORY_SUBSCRIPTIONS: "DECONFLICTION_HISTORY_SUBSCRIPTIONS",
  DECONFLICTION_SEARCH: "DECONFLICTION_SEARCH",
  DECONFLICTION_RESULTS: "DECONFLICTION_RESULTS",
  DIRECTORY: "DIRECTORY",
  DOWNLOADS: "DOWNLOADS",
  FILE_REPOSITORY: "FILE_REPOSITORY",
  // FILE_REPOSITORY_DIR: "FILE_REPOSITORY_DIR",
  HELP_FAQS: "HELP_FAQS",
  HELP_REPORT_ISSUE: "HELP_REPORT_ISSUE",
  HELP_TRAINING: "HELP_TRAINING",
  HOME: "HOME",
  ICAC: "ICAC",
  INVESTIGATOR_SEARCH: "INVESTIGATOR_SEARCH",
  LANDING: "LANDING",
  LANDING_REDIRECT: "LANDING_REDIRECT",
  LOGIN: "LOGIN",
  MANAGE_GROUPS: "MANAGE_GROUPS",
  MY_PROFILE: "MY_PROFILE",
  NEWSLETTER: "NEWSLETTER",
  NOT_FOUND: "NOT_FOUND",
  NOTIFICATIONS: "NOTIFICATIONS",
  PERFORMANCE_MEASURES: "PERFORMANCE_MEASURES",
  PM_REPORT: "PM_REPORT",
  PM_MANAGE: "PM_MANAGE",
  PM_OJJDP_SUBMIT: "PM_OJJDP_SUBMIT",
  PM_ENTER_MEASURES: "PM_ENTER_MEASURES",
  PM_APPROVE_MEASURES: "PM_APPROVE_MEASURES",
  PM_REPORT_DETAILS: "PM_REPORT_DETAILS",
  REGISTER: "REGISTER",
  REPORT_ISSUE: "REPORT_ISSUE",
  RESET_PASSWORD: "RESET_PASSWORD",
  USERS_MANAGE: "USERS_MANAGE",
  TOOLS_EXIF_READER: "TOOLS_EXIF_READER",
  TOOLS_FILE_HASHER: "TOOLS_FILE_HASHER",
  TOOLS_MEMBER_SYSTEMS: "TOOLS_MEMBER_SYSTEMS",
  TOOLS_USEFUL_LINKS: "TOOLS_USEFUL_LINKS",
  TWO_FACTOR: "TWO_FACTOR",
  // TOOLS_WORST_RECENT_OFFENDERS: "TOOLS_WORST_RECENT_OFFENDERS",
};

const routes = [
  {
    key: routeKeys.LANDING_REDIRECT,
    path: "/landing",
    exact: true,
    redirect: "/landing/login",
    fallback: <Loading />,
  },
  {
    key: routeKeys.LANDING,
    path: "/landing",
    exact: false,
    component: lazy(() => import("../landing/Landing")),
    fallback: <Loading />,
    privateRoute: false,
    routes: [
      {
        key: routeKeys.AGREEMENT_TEXT,
        path: "/landing/agreement_text",
        exact: true,
        component: lazy(() => import("../agreement-text/AgreementText")),
        fallback: <Loading />,
      },
      {
        key: routeKeys.LOGIN,
        path: "/landing/login",
        exact: true,
        component: lazy(() => import("../login/LoginFlow")),
        fallback: <Loading />,
      },
      {
        key: routeKeys.REGISTER,
        path: "/landing/register",
        exact: true,
        component: lazy(() => import("../register/Register")),
        fallback: <Loading />,
      },
      {
        key: routeKeys.REPORT_ISSUE,
        path: "/landing/report_issue",
        exact: true,
        component: lazy(() => import("../report-issue/ReportIssue")),
        fallback: <Loading />,
      },
      {
        key: routeKeys.RESET_PASSWORD,
        path: "/landing/reset_password",
        exact: true,
        component: lazy(() => import("../reset-password/ResetPassword")),
        fallback: <Loading />,
      },
      {
        key: routeKeys.HELP,
        path: "/landing/help",
        exact: true,
        component: lazy(() => import("../landing/Help")),
        fallback: <Loading />,
      },
    ],
  },
  {
    key: routeKeys.ICAC,
    path: "/",
    exact: false,
    component: lazy(() => import("../icac/ICAC")),
    fallback: <Loading />,
    privateRoute: true,
    roles: ["isUser"],
    routes: [
      {
        key: routeKeys.HOME,
        path: "/",
        exact: true,
        component: lazy(() => import("../home/Home")),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.ABOUT_US,
        path: "/about_us",
        exact: true,
        component: lazy(() => import("../about-us/AboutUs")),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.ADMIN_TOOLS,
        path: "/admin_tools",
        exact: true,
        privateRoute: true,
        component: lazy(() => import("../admin/AdminPage")),
        fallback: <Loading />,
        roles: ["isAdmin"],
      },
      {
        key: routeKeys.API_CALLS,
        path: "/decon-calls",
        exact: true,
        component: lazy(() => import("../admin/ui/AdminDeconCallsUI")),
        fallback: <Loading />,
        roles: ["isAdmin"],
      },

      {
        key: routeKeys.CASE_REFERRALS,
        path: "/case_referrals",
        exact: true,
        component: lazy(() => import("../case-referrals/CaseReferrals")),
        fallback: <Loading />,
        roles: ["isCommander", "isAdmin", "isCaseUser", "isCaseMgr"],
      },

      {
        key: routeKeys.CASE_REFERRALS_REMINDERS,
        path: "/case_referrals/reminders",
        exact: true,
        component: lazy(() => import("../case-referrals/Reminders")),
        fallback: <Loading />,
        roles: ["isCommander", "isAdmin", "isCaseUser", "isCaseMgr"],
      },
      {
        key: routeKeys.CASE_REFERRALS_REPORTS,
        path: "/case_referrals/reports",
        exact: true,
        component: lazy(() => import("../case-referrals/Reports/CaseReports")),
        fallback: <Loading />,
        roles: ["isCommander", "isAdmin", "isCaseMgr", "isCaseUser"],
      },
      {
        key: routeKeys.CASE_REFERRALS_FILE_CLASSIFICATION,
        path: "/case_referrals/file_classification",
        exact: true,
        component: lazy(() =>
          import("../case-referrals/FileClassification/FileClassification")
        ),
        fallback: <Loading />,
        roles: ["isCommander", "isAdmin", "isCaseMgr"],
      },
      // {
      //   key: routeKeys.CASE_REFERRALS_CUSTOM_REPORTS,
      //   path: "/case_referrals/customreports",
      //   exact: true,
      //   component: lazy(() =>
      //     import("../case-referrals/Reports/CustomReports")
      //   ),
      //   fallback: <Loading />,
      //   roles: ["isCommander", "isAdmin", "isCaseUser", "isCaseMgr"],
      // },
      // {
      //   key: routeKeys.CASE_REFERRALS_CTASSIGNED_REPORTS,
      //   path: "/case_referrals/ctassignedreports",
      //   exact: true,
      //   component: lazy(() => import("../case-referrals/Reports/CTAssigned")),
      //   fallback: <Loading />,
      //   roles: ["isCommander", "isAdmin", "isCaseUser", "isCaseMgr"],
      // },
      // {
      //   key: routeKeys.CASE_REFERRALS_CTEFFICIENCY_REPORTS,
      //   path: "/case_referrals/ctefficiencyreports",
      //   exact: true,
      //   component: lazy(() => import("../case-referrals/Reports/CTEfficiency")),
      //   fallback: <Loading />,
      //   roles: ["isCommander", "isAdmin", "isCaseUser", "isCaseMgr"],
      // },
      {
        key: routeKeys.CASE_REFERRAL_MANAGE,
        path: "/case_referrals/manage:slug",
        exact: true,
        component: lazy(() => import("../case-referrals/Manage/ManageCase")),
        fallback: <Loading />,
        roles: ["isCommander", "isAdmin", "isCaseUser", "isCaseMgr"],
      },
      {
        key: routeKeys.CHANGE_PASSWORD,
        path: "/user/change_pass",
        exact: true,
        component: lazy(() => import("../user/ChangePass")),
        fallback: <div>Loading...</div>,
        roles: ["isUser"],
      },
      {
        key: routeKeys.CT_NOTIFICATIONS,
        path: "/ct_notifications",
        exact: true,
        component: lazy(() => import("../user/CTNotifications.js")),
        fallback: <div>Loading...</div>,
        roles: ["isUser"],
      },
      {
        key: routeKeys.DECONFLICTION_SEARCH,
        path: "/deconfliction/search",
        exact: true,
        component: lazy(() =>
          import("../deconfliction/deconflict/ui/Deconflict")
        ),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.DECONFLICTION_RESULTS,
        path: "/deconfliction/results",
        exact: true,
        component: lazy(() =>
          import("../deconfliction/decon-results/ui/DeconResultsBase")
        ),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.DECONFLICTION_HISTORY_SUBSCRIPTIONS,
        path: "/deconfliction/history_subscriptions",
        exact: true,
        component: lazy(() =>
          import(
            "../deconfliction/history-subscriptions/History_Subscription.js"
          )
        ),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.DIRECTORY,
        path: "/directory",
        exact: true,
        component: lazy(() => import("../directory/Directory.js")),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.DOWNLOADS,
        path: "/downloads",
        exact: true,
        component: lazy(() => import("../downloads/UserDownload.js")),
        fallback: <Loading />,
        roles: ["isCommander", "isAdmin", "isCaseUser", "isCaseMgr"],
      },
      {
        key: routeKeys.FILE_REPOSITORY,
        path: "/file_repository",
        exact: true,
        component: lazy(() => import("../file-repository/FileRepository")),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      // {
      //   key: routeKeys.FILE_REPOSITORY_DIR,
      //   path: "/file_repository/file_repository_dir:slug",
      //   exact: true,
      //   component: lazy(() => import("../file-repository/Files")),
      //   fallback: <Loading />,
      //   roles: ["isCommander"],
      // },
      // {
      //   key: routeKeys.GRANT_REPORTS,
      //   path: "/grant_reports",
      //   exact: true,
      //   component: lazy(() => import("../admin/ui/GrantReports")),
      //   fallback: <Loading />,
      //   roles: ["isAdmin"],
      // },
      {
        key: routeKeys.HELP_FAQS,
        path: "/help/faqs",
        exact: true,
        component: lazy(() => import("../help/FAQs")),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      // {
      //   key: routeKeys.HELP_RELEASE_NOTES,
      //   path: "/help/release_notes",
      //   exact: true,
      //   component: lazy(() => import("../help/HelpReleaseNotes")),
      //   fallback: <Loading />,
      //   roles: ["isUser"],
      // },
      {
        key: routeKeys.HELP_REPORT_ISSUE,
        path: "/help/report_issue",
        exact: true,
        component: lazy(() => import("../help/HelpReportIssue")),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.HELP_TRAINING,
        path: "/help/training",
        exact: true,
        component: lazy(() => import("../help/HelpTraining")),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.INVESTIGATOR_SEARCH,
        path: "/investigator_search",
        exact: true,
        component: lazy(() =>
          import("../investigator-search/InvestigatorSearch")
        ),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.NEWSLETTER,
        path: "/newsletter",
        exact: true,
        component: lazy(() => import("../newsletter/Newsletter")),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.NOT_FOUND,
        path: "/not_found",
        exact: true,
        component: lazy(() => import("../not-found/NotFound")),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.NOTIFICATIONS,
        path: "/notifications",
        exact: true,
        component: lazy(() => import("../notifications/Notifications")),
        fallback: <Loading />,
        roles: ["isAdmin"],
      },
      {
        key: routeKeys.MANAGE_GROUPS,
        path: "/managegroups",
        exact: true,
        component: lazy(() => import("../manage/Groups/ManageGroup")),
        fallback: <Loading />,
        roles: ["isAdmin", "isCommander"],
      },
      {
        key: routeKeys.PERFORMANCE_MEASURES,
        path: "/performance_measures",
        exact: true,
        component: lazy(() =>
          import("../performance-measures/dashboard/PerformanceMeasures")
        ),
        fallback: <Loading />,
        roles: ["isCommander", "isAdmin", "isPmMgr", "isPMUser"],
      },
      {
        key: routeKeys.PM_MANAGE,
        path: "/performance_measures/manage",
        exact: true,
        component: lazy(() =>
          import("../performance-measures/manage/ManageUI")
        ),
        roles: ["isCommander", "isAdmin", "isPmMgr"],
      },
      {
        key: routeKeys.PM_REPORT,
        path: "/performance_measures/report",
        exact: true,
        component: lazy(() =>
          import("../performance-measures/report/ReportUI")
        ),
        roles: ["isCommander", "isAdmin", "isPmMgr", "isPMUser"],
      },

      {
        key: routeKeys.PM_APPROVE_MEASURES,
        path: "/performance_measures/pm_approve_measures",
        exact: true,
        component: lazy(() =>
          import("../performance-measures/approve-measures/ApproveMeasures")
        ),
        fallback: <Loading />,
        roles: ["isCommander", "isAdmin", "isPmMgr"],
      },
      {
        key: routeKeys.PM_REPORT_DETAILS,
        path: "/performance_measures/report_details:slug",
        exact: true,
        component: lazy(() =>
          import("../performance-measures/approve-measures/ReportDetails")
        ),
        fallback: <Loading />,
        roles: ["isCommander", "isAdmin", "isPmMgr"],
      },
      {
        key: routeKeys.PM_OJJDP_SUBMIT,
        path: "/performance_measures/OJJDP-Submit",
        exact: true,
        component: lazy(() =>
          import("../performance-measures/OJJDPSub/OJJDPSub")
        ),
        roles: ["isCommander", "isAdmin", "isPmMgr"],
      },
      {
        key: routeKeys.PM_ENTER_MEASURES,
        path: "/performance_measures/enter_measures",
        exact: true,
        component: lazy(() =>
          import("../performance-measures/enter_measures/ui/EnterMeasuresUI")
        ),
        fallback: <Loading />,
        roles: ["isCommander", "isAdmin", "isPmMgr", "isPMUser"],
      },
      {
        key: routeKeys.USERS_MANAGE,
        path: "/manageusers",
        exact: true,
        component: lazy(() => import("../manage/Users/Manage.js")),
        fallback: <Loading />,
        roles: ["isCommander", "isAdmin"],
      },
      // {
      //   key: routeKeys.USER_MANAGE,
      //   path: "/manageusers/manageuser",
      //   exact: true,
      //   component: lazy(() => import("../taskforces/ManageUser")),
      //   fallback: <Loading />,
      //   roles: [ "isCommander", "isAdmin" ],
      // },
      {
        key: routeKeys.TOOLS_EXIF_READER,
        path: "/tools/exif_reader",
        exact: true,
        component: lazy(() =>
          import("../tools/ExifReader/ui/ToolsEXIFReaderUI")
        ),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.TOOLS_FILE_HASHER,
        path: "/tools/file_hasher",
        exact: true,
        component: lazy(() =>
          import("../tools/fileHasher/ui/ToolsFileHasherUI")
        ),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.TOOLS_MEMBER_SYSTEMS,
        path: "/tools/member_systems",
        exact: true,
        component: lazy(() => import("../tools/ToolsMemberSystems")),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.TOOLS_USEFUL_LINKS,
        path: "/tools/useful_links",
        exact: true,
        component: lazy(() => import("../tools/ToolsUsefulLinks")),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      {
        key: routeKeys.TWO_FACTOR,
        path: "/two_factor",
        exact: true,
        component: lazy(() => import("../user/TwoFactorAuthentication.js")),
        fallback: <Loading />,
      },
      {
        key: routeKeys.MY_PROFILE,
        path: "/my_profile",
        exact: true,
        privateRoute: true,
        component: lazy(() => import("../user/MyProfile")),
        fallback: <Loading />,
        roles: ["isUser"],
      },
      // {
      //   key: routeKeys.VIEW_AS,
      //   path: "/view_as",
      //   exact: true,
      //   privateRoute: true,
      //   component: lazy(() => import("../admin/view/ViewAs")),
      //   fallback: <Loading />,
      //   roles: ["isAdmin"],
      // },
    ],
  },
];

export default routes;
